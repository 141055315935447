<template>
  <div  class=" px-4 py-2">
    <div>
      <div class="text-base font-bold">执行区</div>
      <div></div>
      <div>
        <a-table
          :data-source="workspace"
          :columns="workspaceColumns"
          :scroll="{ x: 1000 }"
          :pagination="false"
        >
          <template slot="operation" slot-scope="text, record">
            <div class="flex">
              <a-button type="primary" class="mr-2" @click="visible = true"
                >执行</a-button
              >
              <a-button
                type="default"
                class="mr-2"
                @click="operation(record.key, 1)"
                >取消</a-button
              >
              <a-button
                type="danger"
                class="mr-2"
                @click="operation(record.key, -1)"
                >删除</a-button
              >
            </div>
          </template>
        </a-table>
      </div>
    </div>
    <div class="mt-4">
      <div class=" text-base font-bold">排队区</div>
      <a-table
        :data-source="queue"
        :columns="columns"
        :scroll="{ x: 1000 }"
        :pagination="pagination"
      ></a-table>
    </div>
    <div class="mt-4">
      <div class="py-3 text-base font-bold">已删除</div>
      <a-table
        :data-source="deleteList"
        :columns="columns"
        :scroll="{ x: 1000 }"
        :pagination="pagination1"
      ></a-table>
    </div>
    <a-modal v-model="visible" title="确认框" @ok="handleOk">
      <div v-if="workspace[0]">
        <div class="flex items-center py-2" v-for="(item, index) in showTitle">
          <div class="text-gray-400 div_left">{{ item }}：</div>
          <div class="font-bold div_right">
            {{ workspace[0][showKey[index]] }}
          </div>
        </div>
      </div>
    </a-modal>
    <a-modal v-model="show" title="时间选择" @ok="operationOrder('canel')">
      <div v-if="workspace[0]">
        <div class="flex items-center py-2">
          <div class="text-gray-400 div_left">重新执行时间：</div>
          <div class="flex items-center font-bold div_right">
            <a-date-picker :disabled-date="disabledDate" @change="dateChange" />
            <div class="mx-1"></div>
            <a-time-picker format="HH:mm" @change="timeChange" />
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { updateStatus, orderWork, collectList } from "@/api/collect";
import moment from "moment";
export default {
  data() {
    return {
      workspace: [],
      queue: [],
      columns: [
        {
          title: "订单编号",
          dataIndex: "ordersn",
          // fixed: "left",
          width: 230,
        },
        { title: "商户名称", dataIndex: "member_name", width: 200 },
        {
          title: "预约时间",
          dataIndex: "dateTime",
          width: 250,
        },
        {
          title: "采集机型",
          dataIndex: "name",
          width: 100,
        },
        {
          title: "机器人名称",
          dataIndex: "type_name",
          width: 120,
        },
        {
          title: "编码",
          dataIndex: "code",
          width: 150,
        },
        {
          title: "规格",
          dataIndex: "size",
          width: 80,
        },
      ],
      workspaceColumns: [],
      pagination: {
        // 关于页码的配置属性
        total: 0,
        pageSize: 10,
        pageNo: 1,
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) =>
          this.onSizeChange(current, pageSize), // 改变每页数量时更新显示
        onChange: (page, pageSize) => this.onPageChange(page, pageSize), //点击页码事件
      },
      deleteList: [],
      pagination1: {
        // 关于页码的配置属性
        total: 0,
        pageSize: 4,
        pageNo: 1,
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) =>
          this.onSizeChange1(current, pageSize), // 改变每页数量时更新显示
        onChange: (page, pageSize) => this.onPageChange1(page, pageSize), //点击页码事件
      },
      visible: false,
      showTitle: ["订单编号", "采集机型", "类别", "编码", "规格"],
      showKey: ["ordersn", "name", "type_name", "code", "size"],

      operationType: 0, // 记录对执行区订单的操作值

      show: false, // 重新执行时间弹窗标识
      date: "", // 重新开始时间日期
      start_time: "", // 重新开始时间
    };
  },
  created() {
    this.workspaceColumns = [];
    this.workspaceColumns.push(...this.columns, {
      title: "操作",
      dataIndex: "operation",
      scopedSlots: { customRender: "operation" },
      fixed: "right",
      width: 250,
    });
    this.getData(1);
    this.getDeleteList();
  },
  methods: {
    // 获取工作区列表
    disabledDate(current){
      return current && current <moment().subtract(1,'days').endOf('day')
    },
    getData(page) {
      console.log("执行给g");
      orderWork({
        page,
        limit: this.pagination.pageSize,
      }).then((res) => {
        console.log("获取的采集工作列表", res);
        if (res.code == 200) {
          this.queue = [];
          res.data.list.forEach((item) => {
            item.key = item.ordersn;
            item.dateTime = `${item.date}  ${item.start_time} ~ ${item.end_time}`;
          });
          if (page == 1) {
            let workspace = res.data.list.splice(0, 1); // 第一项默认进入执行区
            this.workspace = [];
            this.workspace.push(...workspace);
          }

          this.queue.push(...res.data.list);
          this.pagination.total = res.data.count - 1;
        }
      });
    },
    // 分页里size改变触发的函数
    onSizeChange(_, pageSize) {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = pageSize;
      this.getData(1);
    },
    // 页面改变时
    onPageChange(page, pageSize) {
      console.log(page, pageSize);
      this.pagination.pageNo = page;
      this.getData(page);
    },
    // 分页里size改变触发的函数
    onSizeChange1(_, pageSize) {
      this.pagination1.pageNo = 1;
      this.pagination1.pageSize = pageSize;
      this.getDeleteList(1);
    },
    // 页面改变时
    onPageChange1(page, pageSize) {
      console.log(page, pageSize);
      this.pagination1.pageNo = page;
      this.getDeleteList(page);
    },
    // 获取删除列表
    getDeleteList() {
      // 请求数据
      collectList({
        type: 0,
        page: this.pagination1.pageNo,
        limit: this.pagination1.pageSize,
      })
        .then((res) => {
          console.log("删除列表", res);
          res.data.list.forEach((item, index) => {
            item.key = index;
            item.dateTime = `${item.date}  ${item.start_time} ~ ${item.end_time}`;
          });
          this.deleteList = [];
          this.deleteList.push(...res.data.list);
          this.pagination1.total = res.data.count;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 执行区点击取消和删除触发的方法
    operation(_, type) {
      this.operationType = type;
      this.$confirm({
        title: "提示",
        content:
          type == 1 ? "是否确定取消采集该订单？" : "是否确定删除该订单？",
        onOk: () => {
          if (type == -1) {
            this.operationOrder();
          } else {
            this.show = true;
          }
        },
        onCancel() {},
      });
    },
    // 取消和删除订单
    operationOrder(type) {
      let data = {
        order_id: this.workspace[0].id,
        order_status: this.operationType,
      };
      if (type) {
        // 取消订单需要选择重新开始时间
        if (this.date == "") {
          this.$message.error("请选择重新开始日期");
          return;
        }
        if (this.start_time == "") {
          this.$message.error("请选择重新开始时间");
          return;
        }
        data.date = this.date;
        data.start_time = this.start_time;
      }
      updateStatus(data).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          type ? (this.show = false) : "";
          this.workspace = [];
          this.pagination.pageNo = 1;
          this.getData(1);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 执行区点击执行触发的方法
    handleOkold() {
      updateStatus({
        order_id: this.workspace[0].id,
        order_status: 0,
      }).then((res) => {
        if (res.code == 200) {
          localStorage.setItem(
            "orderInfo",
            JSON.stringify({
              sn: this.workspace[0].ordersn,
              id: this.workspace[0].id,
              size: this.workspace[0].size,
              order_id: this.workspace[0].id,
            })
          );
          this.$router.push("/edit");
        } else {
          alert('返回结果失败')
          this.$message.error(res.msg);
        }
      });
    },
    handleOk() {
          localStorage.setItem(
            "orderInfo",
            JSON.stringify({
              sn: this.workspace[0].ordersn,
              id: this.workspace[0].id,
              size: this.workspace[0].size,
              order_id: this.workspace[0].id,
            })
          );
          this.$router.push("/edit");
    },
    dateChange(date, dateString) {
      console.log(date, dateString);
      this.date = dateString;
    },
    timeChange(time, timeString) {
      console.log(time, timeString);
      this.start_time = timeString;
    },
  },
};
</script>
<style lang="less" scoped>
.ant-empty-normal{margin: 0 auto !important;}
.overf{overflow: scroll;}
.div_left {
  width: 100px;
  text-align: right;
}
.div_right {
  flex: 1;
  text-align: left;
}
</style>
